<!--SPINNER-->
<div class="dialog-overlay-spinner" *ngIf="showLoadingSpinner">
  <app-overlay-spinner [diameter]="120" [mode]="'indeterminate'"></app-overlay-spinner>
</div>
<div [ngStyle]="{'max-height': media.isActive('lt-md') ? '100vh' : '37rem'}" class="create-edit-custom-property-dialog" fxLayout="column" fxLayoutAlign="space-between start">
  <div class="dialog-main-content" fxLayout="column" fxLayoutAlign="start start">
    <!--TITLE-->
    <div class="dialog-title" fxLayout="row" fxLayoutAlign="start center">
      <span>{{ (data?.propertyId ? translations.assetView.createEditCustomProperty.editTitle : translations.assetView.createEditCustomProperty.createTitle) | translate }}</span>
    </div>
    <!--FORM-->
    <form class="dialog-form" id="custom-property-form" [formGroup]="customPropertyFormGroup" fxLayout="column" fxLayoutAlign="start start">
      <!--PROPERTY NAME-->
      <div class="form-name" fxLayout="column" fxLayoutAlign="start start">
        <span class="form-field-label">{{ translations.assetView.createEditCustomProperty.propertyFieldName | translate }}</span>
        <mat-form-field appearance="outline">
          <mat-label>{{ translations.assetView.createEditCustomProperty.propertyFieldName | translate }}</mat-label>
          <input matInput formControlName="name">
          <mat-error *ngIf="customPropertyFormGroup?.controls?.name?.hasError(getValidationError()?.required)">
            {{ translations.assetView.createEditCustomProperty.emptyPropertyWarningText | translate }}
          </mat-error>
          <mat-error *ngIf="customPropertyFormGroup?.controls?.name?.hasError(getValidationError()?.duplicate)">
            {{ translations.assetView.createEditCustomProperty.duplicatePropertyWarningText | translate }}
          </mat-error>
        </mat-form-field>
      </div>
      <!--PROPERTY VALUES-->
      <div [ngStyle]="{'height': media.isActive('lt-md') ? 'calc(100vh - 2rem - 260px)' : ''}" class="form-values" fxLayout="column" fxLayoutAlign="start start">
        <span class="form-field-label">{{ translations.assetView.createEditCustomProperty.valuesFieldName | translate }}</span>
        <div [ngClass]="media.isActive('lt-md') ? 'change-max-height' : ''" class="form-values-container" fxLayout="column" fxLayoutAlign="start start" fxFlex="1 1 auto">
          <ng-container *ngFor="let propertyValue of getValuesFormControls(); index as i; let last = last" [formGroup]="propertyValue">
            <div class="form-value" fxLayout="row" fxLayoutAlign="space-between center">
              <!--PROPERTY VALUES - FIELD-->
              <mat-form-field fxFlex="auto" appearance="outline">
                <mat-label>{{ translations.assetView.createEditCustomProperty.valuesFieldName | translate }}</mat-label>
                <input #formList matInput formControlName="value">
                <mat-error *ngIf="propertyValue?.controls?.value?.hasError(getValidationError()?.required)">
                  {{ translations.assetView.createEditCustomProperty.emptyValueWarningText | translate }}
                </mat-error>
                <mat-error *ngIf="propertyValue?.controls?.value?.hasError(getValidationError()?.duplicate)">
                  {{ translations.assetView.createEditCustomProperty.duplicateValueWarningText | translate }}
                </mat-error>
              </mat-form-field>
              <!--PROPERTY VALUES - DELETE ICON-->
              <div *ngIf="isDeleteValueIconVisible()" class="remove-icon-container" fxLayout="row" fxLayoutAlign="start center">
                <mat-icon (click)="onDeleteValueClick(i, propertyValue?.controls)" class="icon color-zonar-blue material-icons-outlined">delete</mat-icon>
              </div>
            </div>
            
          </ng-container>
          <!--PROPERTY VALUES - ADD-->
          <div class="form-buttons" fxLayout="row" fxLayoutAlign="start center">
            <button class="zonar-button zonar-button-outlined button-size-l" [disabled]="!isAddValueButtonDisable()" mat-button mat-stroked-button type="button" (click)="onAddValueClick()" fxLayoutAlign="center center">
              <mat-icon class="material-icons-outlined">add</mat-icon>
              <span class="text">{{ translations.assetView.createEditCustomProperty.addValueButtonText | translate }}</span>
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
  <!--BUTTON-->
  <div class="dialog-buttons" fxLayout="row" [ngClass]="data?.propertyId ? 'edit-dialog-buttons' : 'create-dialog-buttons'">
    <button class="stroked-button button-size-l" *ngIf="data?.propertyId" mat-button mat-stroked-button type="button" (click)="onDeletePropertyClick(data.propertyId)" fxLayoutAlign="center center">
      {{ media.isActive('lt-md') ? (translations.assetView.createEditCustomProperty.deletePropertyButtonTextMobile | translate) : translations.assetView.createEditCustomProperty.deletePropertyButtonText | translate }}
    </button>
    <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="12px">
      <button class="stroked-button -button-size-l" mat-button mat-stroked-button type="button" (click)="onCancelDialogClick()" fxLayoutAlign="center center">
        {{ translations.assetView.createEditCustomProperty.cancelButtonText | translate }}
      </button>
      <button class="raised-button" mat-raised-button type="submit" fxLayoutAlign="center center" (click)="onConfirmDialogClick()" [disabled]="isSaveButtonDisable()">
        {{ translations.assetView.createEditCustomProperty.saveButtonText | translate }}
      </button>
    </div>
  </div>
</div>
