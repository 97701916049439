import { getTestBed, TestBed, ComponentFixture } from '@angular/core/testing';
import { StringPerm } from './models/permission.model';
import { } from 'jasmine';
import { DateFormat, LegacyLanguageMap, LegacySupportLanguages, NALanguageCodes } from './consts/app.const';
import { formatDate } from '@angular/common';
import { EGroupPolicySetting } from './consts/group.const';
import { UserType } from './consts/user.const';

const uuidURLRegExp = '\\/([0-9a-f]{8}\\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\\b[0-9a-f]{12})';
const stringRegExp = '(([a-zA-Z])\\w+)';
export const uuidRexExp = '([0-9a-f]{8}\\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\\b[0-9a-f]{12})';
export const sortType = {
  ASC: 'ASC',
  DESC: 'DESC'
};
export const notificationDuration = 7;

// parse string of param by URL
export function parseStringByURL(url, paramName) {
  const paramRegExp = new RegExp(`${paramName}=${stringRegExp}`);
  const match = url?.match(paramRegExp) || null;

  return match ? match[1] : null;
}

// parse uuid of param by URL
export function parseUUIDByURL(url, paramName) {
  const paramRegExp = new RegExp(`${paramName}${uuidURLRegExp}`);
  const match = url?.match(paramRegExp) || null;

  return match ? match[1] : null;
}

export function sortBy(array: Array<any>, propToRefer: string = null, type: string = sortType.ASC) {
  if (type === sortType.DESC) {
    if (propToRefer) {
      return array.sort((a, b) =>
        (a[propToRefer].toUpperCase() < b[propToRefer].toUpperCase()) ? 1
          : ((b[propToRefer].toUpperCase() < a[propToRefer].toUpperCase()) ? -1
            : 0));
    }
    return array.sort((a, b) => (a.toUpperCase() < b.toUpperCase()) ? 1 : ((b.toUpperCase() < a.toUpperCase()) ? -1 : 0));
  } else {
    if (propToRefer) {
      return array.sort((a, b) =>
        (a[propToRefer].toUpperCase() > b[propToRefer].toUpperCase()) ? 1
          : ((b[propToRefer].toUpperCase() > a[propToRefer].toUpperCase()) ? -1
            : 0));
    }
    return array.sort((a, b) => (a.toUpperCase() > b.toUpperCase()) ? 1 : ((b.toUpperCase() > a.toUpperCase()) ? -1 : 0));
  }
}

export function removeDuplicateObjectByProp(dataList, propToRefer) {
  return dataList.filter((value, index: number, array) =>
    array.findIndex((currentValue) => currentValue[propToRefer] === value[propToRefer]) === index);
}

export function hasDifferentStringArray(baseArray: Array<string>, compareArray: Array<string>) {
  if (baseArray?.length === compareArray?.length) {
    for (const item of baseArray) {
      if (!compareArray.find(div => div === item)) {
        return true;
      }
    }
    return false;
  }
  return true;
}

export function buildStringPermission(params: StringPerm) {
  return Object.values(params).join(':');
}


// Remove whitespace from both sides of string type property.
export function trimStrings(inputObject: Partial<any>): void {
  if (inputObject) {
    Object.keys(inputObject).forEach(key => {
      if (typeof (inputObject[key]) === 'string') {
        inputObject[key] = inputObject[key].trim();
      }
    });
  }
}

/**
 * Remove multiple spaces with a single space.
 *
 * @param str string
 * @returns string
 */
export const removeSpaces = (str: string): string => {
  const regEx = new RegExp(/\s{2,}/g);
  return str.replace(regEx, ' ').trim();
};

export function toTitleCase(str: string): string {
  return str?.split(' ')
    .map(strParts => {
      if (strParts !== '') {
        return strParts[0].toUpperCase() + strParts.substring(1).toLowerCase();
      }
      return '';
    })
    .join(' ');
}

export const configureTestSuite = () => {
  const testBedApi: any = getTestBed();
  const originReset = TestBed.resetTestingModule;

  beforeEach(() => {
    TestBed.resetTestingModule();
    TestBed.resetTestingModule = () => TestBed;
  });

  afterEach(() => {
    testBedApi._activeFixtures.forEach((fixture: ComponentFixture<any>) => fixture.destroy());
    testBedApi._instantiated = false;
  });

  afterAll(() => {
    TestBed.resetTestingModule = originReset;
    TestBed.resetTestingModule();
  });
};

export const buildLocalTime = (inputDate: any, isZeroOffset = true): string => {
  if (inputDate) {
    return formatDate(
      isZeroOffset ? new Date(inputDate + 'Z') : new Date(inputDate),
      DateFormat.NA,
      'en'
    ).toString();
  }
  return null;
};

export const validateSuccessStatusAPI = (apiResponses): boolean => {
  return apiResponses.every((apiResponse: any) => apiResponse.ok);
};

export const getSupportedLanguageCodes = (language: string) => {
  if (LegacySupportLanguages.find(lang => lang === language)) {
    return LegacyLanguageMap[language].useLanguage;
  }
  return language;
};

export const isGroupPolicy = (setting: string): boolean => {
  const settingValuesForGroupPolicy = [
    EGroupPolicySetting.PENDING.toString(),
    EGroupPolicySetting.ENABLING.toString(),
    EGroupPolicySetting.ENABLING_ERROR.toString(),
    EGroupPolicySetting.ENABLED.toString(),
  ];

  return settingValuesForGroupPolicy.includes(setting);
};

export const buildTranslatedUserLoginType = (credentialType: string, translated: { [key in UserType]: string }, defaultReturn: string) => {
  if (credentialType) {
    return credentialType
      .replace(UserType.STANDARD, translated.STANDARD)
      .replace(UserType.MANAGED, translated.MANAGED)
      .replace(UserType.LEGACY_OPERATOR, translated.LEGACY_OPERATOR)
      .replace(UserType.PASSWORDLESS, translated.PASSWORDLESS)
      .replace(';', ', ');
  }
  return defaultReturn;
};

export function isPaginationCallingInZuiTable(params: { page: number, per_page: number }): boolean {
  if (!params) return false;
  return Object.keys(params).length === 2 && Boolean(params.page) && Boolean(params.per_page);
}
