import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ComponentViewMode } from '@app/core/consts/app.const';
import { ETenantType } from '@app/core/consts/tenant.const';
import { ICreateEditViewTree } from '@app/core/models/policy.model';
import { TenantDataService } from '@app/core/services/data/tenant-data.service';
import { notificationDuration } from '@app/core/utils';
import { createTenant, updateTenant } from '@app/shared/data-stores/tenant/tenant-data-store.action';
import { selectCreateUpdateSuccess } from '@app/shared/data-stores/tenant/tenant-data-store.selects';
import { Store } from '@ngrx/store';
import { ZonarUiNotificationsService } from '@zonar-ui/notifications';
import { Subscription } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-create-edit-with-tenant-dialog',
  templateUrl: './create-edit-with-tenant-dialog.component.html',
  styleUrls: ['./create-edit-with-tenant-dialog.component.scss']
})
export class CreateEditWithTenantDialogComponent implements OnInit {
  inputData: ICreateEditViewTree;
  dataSubscription: Subscription;
  hasLoadSuccess = true;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private store: Store<any>,
    private tenantDataService: TenantDataService,
    private zonarUiNotificationsService: ZonarUiNotificationsService,
    private dialogRef: MatDialogRef<any>,

  ) { }

  ngOnInit(): void {
    this.inputData = {
      componentMode: this.data?.mode,
      dataList: this.data?.dataList,
      enableDeterminate: this.data?.enableDeterminate,
      initInfo: this.data?.initInfo,
      translated: this.data?.dialogText,
      isDialog: false,
      feature: this.data?.feature,
      dataValidation: this.data?.dataValidation,
      allItemsChecked: this.data?.allItemsChecked,
      disableSubItemSelection: this.data?.disableSubItemSelection,
      companyLevelCapaSelectedList: this.data?.companyLevelCapaSelectedList,
      allRoleCompanyLevelCapaList: this.data?.allRoleCompanyLevelCapaList,
      companyId: this.data?.companyId,
      divisions: this.data?.divisions
    };

    this.buildTenantListAfterCreateUpdate();
  }

  buildTenantListAfterCreateUpdate() {
    this.store.select(selectCreateUpdateSuccess).pipe(filter(createUpdateTenant => createUpdateTenant !== null)).subscribe(createUpdateTenant => {
      if (createUpdateTenant) {
        this.onOpenSuccessNotification();
      } else {
        this.onOpenErrorNotification();
      }
      this.hasLoadSuccess = true;
      this.dialogRef.close(createUpdateTenant);
    });
  }

  // TODO update translate message success.
  onOpenSuccessNotification() {
    this.zonarUiNotificationsService.openSuccess(
      '',
      'Changes saved successfully.',
      notificationDuration
    );
  }

  // TODO update translate message error.
  onOpenErrorNotification() {
    this.zonarUiNotificationsService.openError(
      '',
      'Something went wrong and we could not save your changes. Please try again',
      notificationDuration
    );
  }


  confirmEmitter(confirmData) {
    this.hasLoadSuccess = false;
    switch (confirmData.data.componentMode) {
      case ComponentViewMode.CREATE:
        this.store.dispatch(createTenant({
          payload: {
            params: {
              name: confirmData.name,
              scope: {
                type: ETenantType.DIVISION_LIST,
                companies: [this.inputData?.companyId],
                divisions: this.tenantDataService.buildDivisionParams(confirmData?.dataChanged, this.inputData?.divisions)
              }
            }
          }
        }));

        break;
      case ComponentViewMode.EDIT:
        // TODO: tenants edit need to be separated into both PUT/PATCH
        const params: any = { name: confirmData.name };
        this.store.dispatch(updateTenant({
          payload: {
            confirmData,
            divisions: this.inputData?.divisions,
            companyId: this.inputData?.companyId,
          }
        }));
        break;

      // TODO handle error
      default:
        break;
    }
  }
}
