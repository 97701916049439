import { createFeatureSelector, createSelector } from '@ngrx/store';
import { TenantsState } from './tenant-data-store.interface';
import { featureKey } from './tenant-data-store.reducer';

export const selectTenantState = createFeatureSelector<TenantsState>(featureKey);

export const selectTenants = createSelector(selectTenantState, state => state?.tenantList);
export const selectTenant = createSelector(selectTenantState, state => state?.tenant);
export const selectCreateUpdateSuccess = createSelector(selectTenantState, state => state?.createUpdateSuccess); 

