export enum TagType {
  ASSET = 'ASSET'
}

export enum IdentifierType {
  NFC = 'NFC'
}

export enum InspectionDevice {
  evirMobile = 'EVIR Mobile'
}

export enum legacyType {
  STANDARD = 'Standard'
}

export enum AssetType {
  VEHICLE = 'vehicle'
}

export enum AssetStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  ANY = 'ANY',
}

export enum AssetHazard {
  YES = 'YES',
  NO = 'NO'
}

export enum VINModelYear {
  FROM = 1980,
  TO = (new Date()).getFullYear() + 1
}

// This is class name for flag-icon-css package usage.
export enum IconClass {
  FLAG_CSS = 'flag-icon flag-icon-'
}

export enum VINProperties {
  MAKE = 'Make',
  MODEL = 'Model',
  MODEL_YEAR = 'Model Year'
}

export enum AssetTablePropertyParams {
  PER_PAGE = 'per_page',
  PAGE = 'page',
  NAME = 'name',
  STATUS = 'status',
  TYPES = 'types',
  IDS = 'ids',
  DIVISIONS = 'divisions',
  PROPERTIES = 'properties',
  SORT = 'sort'
}

export enum AssetListTable {
  CHECKBOX = 'checkbox',
  ICON_STATUS = 'status',
  NAME = 'name',
  TYPE = 'type',
  SUBTYPE = 'subType',
  VIN = ' vin',
  LICENSE = 'license',
  DIV_NAME = 'divisions',
  MODIFIED = 'modified',
  ICON = 'icon',
}

export enum AssetAttributesListTableColDef {
  CHECKBOX = 'checkbox',
  NAME = 'name',
  TYPE = 'type',
  MODEL_YEAR = 'modelYear',
  MODEL = 'model',
  MAKE = 'make',
  PROPERTY = 'customProperty',
  VIN = 'vin',
  HOME_LOCATION = 'homeLocation',
  LICENSE_PLATE = 'licensePlate',
  EXS_ID = 'exsId',
  GPS_ID = 'gpsId',
  EDIT = 'edit',
};

export enum AssetAttributesListTableIndex {
  CHECKBOX = 0,
  NAME,
  TYPE,
  MODEL_YEAR,
  MODEL,
  MAKE,
  PROPERTY,
  VIN,
  HOME_LOCATION,
  LICENSE_PLATE,
  EXS_ID,
  GPS_ID,
  EDIT,
}

export const AssetAttributesColumnWidth = {
  CHECKBOX: '3%',
  NAME: '15%',
  TYPE: '15%',
  MODEL_YEAR: '12%',
  MODEL: '8%',
  MAKE: '7%',
  PROPERTY: '25%',
  VIN: '15%',
  HOME_LOCATION: '15%',
  LICENSE_PLATE: '15%',
  EXS_ID: '15%',
  GPS_ID: '15%',
  EDIT: '8%'
};

export const AssetColumnWidth = {
  CHECKBOX: '3%',
  ICON_STATUS_W_BULK_EDIT: '3%',
  ICON_STATUS: '5%',
  NAME: '12.13%',
  TYPE: '10.97%',
  SUBTYPE: '10.97%',
  VIN: '13.13%',
  LICENSE: '13.13%',
  DIV_NAME: '13.13%',
  MODIFIED_W_EDIT: '19.46%',
  MODIFIED: '21.46%',
  ICON: '2%',
};

export const DivisionColumnWidth = {
  ICON_STATUS: '3%',
  NAME: '14.7%',
  TYPE: '15.06%',
  SUBTYPE: '15.06%',
  VIN: '15.7%',
  LICENSE: '15.7%',
  MODIFIED_W_EDIT: '18.06%',
  MODIFIED: '20.06%',
  ICON: '2%',
};

export enum ColumnAssetList {
  CHECKBOX = 0,
  STATUS,
  NAME,
  TYPE,
  SUBTYPE,
  VIN,
  LICENSE,
  DIVISION,
  MODIFIED,
  EDIT
}
export enum AssetCreateConfig {
  DEFAULT_LENGTH_CHECK = 3,
  VIN_LENGTH_CHECK = 17,
  DELAY_DURATION = 300
}

export const AssetClass = [
  'Class 1',
  'Class 2',
  'Class 3',
  'Class 4',
  'Class 5',
  'Class 6',
  'Class 7',
  'Class 8'
];

export enum AssetSettingItemName {
  ASSET_ACTIVE = 'assetActiveToggle',
  HAZMAT_LOADS = 'hazmatLoadToggle',
}

/**
 * key: entity of assetHeaderRow in translate json file
 * value: path name of AssetParams
 */
export const AssetFieldCheckDup = {
  assetId: 'name',
  vin: 'typeInfo.oemVin',
  cviTag: 'inspectionInfo.identifier',
  regPlate: 'typeInfo.licenseNumber',
  powerUnitNo: 'typeInfo.powerUnitNumber'
};

export const AssetMenuListLabel = {
  activateAssets: 'activate assets',
  deactivateAssets: 'deactivate assets'
};

export enum SearchAssetPropertiesMapping {
  VEHICLE_TYPE = "types",
  YEAR = "modelYears",
  MAKE = "makes",
  MODEL = "models",
  BODY_CLASS = "subtypes",
  ENGINE_CONFIG = "engineConfigs",
  GVWR = "grossVehicleWeightRatings",
  FUEL_TYPE = "fuelTypes"
}

export enum AssetVinStatus {
  ECU_VALID = 'ECU_VALID',
  ECU_PARTIAL = 'ECU_PARTIAL',
  MANUAL_VALID = 'MANUAL_VALID',
  MANUAL_PARTIAL = 'MANUAL_PARTIAL',
  OVERRIDE_VALID = 'OVERRIDE_VALID',
  OVERRIDE_PARTIAL = 'OVERRIDE_PARTIAL'
}
