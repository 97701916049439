import { FeatureKeys } from '@app/core/consts/app.const';
import { FilterSelectOptionsControlName, IconColor, ListManagementType, MiscIcon } from '@app/core/consts/list-management.const';
import { CompanyGroupPolicyUserColumnWidth, UserListGroupPolicyColDef, UNASSIGNED_GROUP_USER, UserStatus, UserStatusIcon, UserType } from '@app/core/consts/user.const';
import { ListManagement } from '@app/core/models/list-management';
import { ZonarUITableCellType as cellType } from '@zonar-ui/table';
import { Translations } from "@app/core/services/i18n/translations.service";
import { FeatureToggleService } from '@app/shared/services/feature-toggle.service';
import { buildTranslatedUserLoginType, toTitleCase } from '@app/core/utils';
import { uniqBy } from 'lodash';
import { IGroup } from '@app/core/models/group.model';
import { UserGroupPolicyCombine } from '@app/core/models/policy.model';
import { CompanySettingAllowList } from '@app/core/models/setting.model';
import { ETenantType } from '@app/core/consts/tenant.const';

export function getUserListIconData(translations: Translations, translated) {
  return {
    [UserStatusIcon.INFO]: translated[translations.companyView.accountList.tableCellManagedUser].toUpperCase(),
    [UserStatusIcon.ACTIVE]: translated[translations.companyView.userList.tableStatusTooltip.active],
    [UserStatusIcon.INACTIVE]: translated[translations.companyView.userList.tableStatusTooltip.inactive]
  };
}

export const UserListIconColor = {
  [UserStatusIcon.ACTIVE]: IconColor.ACTIVE,
  [UserStatusIcon.INACTIVE]: IconColor.INACTIVE,
  [MiscIcon.EDIT]: IconColor.EDIT,
  [UserStatusIcon.INFO]: IconColor.INFO
};

export function getUserListCompanyColumnStyle(featureToggleService: FeatureToggleService) {
  return {
    [UserListGroupPolicyColDef.NAME]: { 'max-width': CompanyGroupPolicyUserColumnWidth.NAME },
    [UserListGroupPolicyColDef.GROUP_POLICY]: { 'max-width': CompanyGroupPolicyUserColumnWidth.GROUP_POLICY },
    [UserListGroupPolicyColDef.DRIVER]: { 'max-width': CompanyGroupPolicyUserColumnWidth.DRIVER },
    [UserListGroupPolicyColDef.LOGIN_TYPE]: { 'max-width': CompanyGroupPolicyUserColumnWidth.LOGIN_TYPE },
    [UserListGroupPolicyColDef.STATUS]: featureToggleService.isFeatureEnabled(FeatureKeys.USER.EDIT) ?
      { 'max-width': CompanyGroupPolicyUserColumnWidth.STATUS_W_EDIT } :
      { 'max-width': CompanyGroupPolicyUserColumnWidth.STATUS },
    [UserListGroupPolicyColDef.EDIT]: { 'display':'flex', 'justify-content': 'flex-end' }
  };
}

export function getUserListColumns(companyId: string, translations: Translations, translated, featureToggleService: FeatureToggleService) {
  return [
    {
      columnDef: UserListGroupPolicyColDef.NAME, header: translated[translations.companyView.userList.tableHeaderName],
      sortable: true, cell: (row: any) => {
        return `${row.user.firstName ?? ''} ${row.user.middleName ?? ''} ${row.user.lastName ?? ''}`;
      },
    },
    {
      columnDef: UserListGroupPolicyColDef.GROUP_POLICY, header: translated[translations.companyView.userList.tableHeaderGroupPolicy].toUpperCase(),
      type: cellType.Portal,
    },
    {
      columnDef: UserListGroupPolicyColDef.DRIVER,
      header: translated[translations.companyView.userList.tableHeaderDriver].toUpperCase(),
      type: cellType.Text,
      headerIcon: MiscIcon.INFO_ICON,
      headerTooltip: translated[translations.companyView.userList.tableHeaderDriverTooltip],
      sortable: false,
      columnStyle: { 'display': 'flex', 'justify-content': 'flex-end' },
      cell: (row: any) => {
        return row?.isUserDriver ?
          translated[translations.companyView.userList.driver.userDriver] :
          translated[translations.companyView.userList.driver.nonUserDriver];
      },
    },
    {
      columnDef: UserListGroupPolicyColDef.LOGIN_TYPE, header: translated[translations.user.loginType].toUpperCase(),
      sortable: false, cell: (row: UserGroupPolicyCombine) => {
        return buildTranslatedUserLoginType(row?.user?.credentialType, {
          STANDARD: translated[translations.user.loginTypes.standard],
          MANAGED: translated[translations.user.loginTypes.managed],
          LEGACY_OPERATOR: translated[translations.user.loginTypes.legacyOperator],
          PASSWORDLESS: translated[translations.user.loginTypes.passwordless],
        }, '-');
      },
    },
    {
      /* status field uses the status of company user directory */
      columnDef: UserListGroupPolicyColDef.STATUS, header: translated[translations.user.profileStatus].toUpperCase(),
      type: cellType.Portal,
    },
    {
      columnDef: UserListGroupPolicyColDef.EDIT,
      header: '',
      type: cellType.Portal,
      sortable: false,
      hidden: !featureToggleService.isFeatureEnabled(FeatureKeys.USER.EDIT),
      columnStyle: { 'display': 'flex', 'justify-content': 'flex-end' },
    }
  ];
}

export const UserListSkeletonLoader = { skeletonLoader: {} };

export function getUserListSearchActionView(translations: Translations, translated): ListManagement {
  return {
    type: ListManagementType.Search,
    searchManagement: {
      controlName: 'name',
      searchQuery: ['name'],
      searchPlaceHolder: translated[translations.companyView.userList.searchPlaceholder],
    },
  };
}

export function getUserListFilterStatusActionView(translations: Translations, translated): ListManagement {
  return {
    type: ListManagementType.Filter,
    filterManagement: {
      options: [
        {
          title: translated[translations.entity.status.active],
          value: UserStatus.ACTIVE
        },
        {
          title: translated[translations.entity.status.inactive],
          value: UserStatus.INACTIVE
        }
      ],
      filterLabel: translated[translations.user.profileStatus],
      isMultiple: true,
      selectOptionsControlName: FilterSelectOptionsControlName.USERS_GROUP.STATUS
    }
  };
}

export function getUserListFilterLoginTypeActionView(translations: Translations, translated): ListManagement {
  return {
    type: ListManagementType.Filter,
    filterManagement: {
      options: [],
      filterLabel: translated[translations.user.loginType],
      isMultiple: true,
      selectOptionsControlName: FilterSelectOptionsControlName.USERS_GROUP.LOGINTYPES
    }
  };
}

export function getUserListFilterLoginTypeOptions(allowList: CompanySettingAllowList, translations: Translations, translated) {
  const options = [];
  if (allowList.hasLegacyOperator) {
    options.push({
      title: translated[translations.user.loginTypes.legacyOperator],
      value: UserType.LEGACY_OPERATOR,
    });
  }

  if (allowList.hasAllowManaged) {
    options.push({
      title: translated[translations.user.loginTypes.managed],
      value: UserType.MANAGED,
    });
  }

  if (allowList.hasLegacyOperator || allowList.hasAllowManaged) {
    options.push({
      title: translated[translations.user.loginTypes.standard],
      value: UserType.STANDARD
    });
  }

  return options;
}

export function getUserListFilterGroup(translations: Translations, translated): ListManagement {
  return {
    type: ListManagementType.Filter,
    filterManagement: {
      options: [],
      filterLabel: translated[translations.companyView.userList.filterLabel.group],
      isMultiple: true,
      sortByStringTitle: false,
      selectOptionsControlName: FilterSelectOptionsControlName.USERS_GROUP.GROUPS
    }
  };
}

export function getUserListFilterGroupOptions(users, translations: Translations, translated) {
  if (!users || !users.length) {
    return [{
      title: translated[translations.companyView.userList.unassignedGroupUser], value: UNASSIGNED_GROUP_USER
    }];
  }

  let uniqueGroup = [];
  users.forEach(user => {
    if (user?.groups) {
      uniqueGroup.push(...user.groups);
    }
  });
  uniqueGroup = uniqBy(uniqueGroup, 'id').map(({ policy, tenant, id }) => ({ title: `${policy} - ${tenant}`, value: id }));
  uniqueGroup.unshift({
    title: translated[translations.companyView.userList.unassignedGroupUser], value: UNASSIGNED_GROUP_USER
  });

  return uniqueGroup;
}

export function getUserListCreateUserButtonManagement(companyId: string, menuList, translations: Translations, translated, featureToggleService: FeatureToggleService) {
  return {
    locateNavigate: `company/${companyId}/create-user`,
    buttonName: featureToggleService.isFeatureEnabled(FeatureKeys.USER.CREATE) ? translated[translations.companyView.userList.newUserButton] : null,
    menuName: translated[translations.companyView.bulkMenu.menuName],
    hasMenu: menuList.length ? true : false,
    menuList: menuList
  };
}

export const UserListCreateUserButton: ListManagement = {
  type: ListManagementType.Button,
  buttonManagement: {
    isDisabledButton: false,
    isOpenDialog: false
  }
};

export function getDisplayUserGroupPolicy(data: any, translations: Translations, translated, companyId: string) {
  let combineGroups = [];
  data?.groups?.forEach(group => {
    combineGroups.push(`${group.policy} - ${group.tenant}`);
  });

  let groupText = `${translated[translations.companyView.userList.unassignedGroupUser]}`;
  let groupTooltipText = '';

  if (data?.groups?.length) {
    groupText = `${data.groups[0].policy}${data.groups[0]?.isEmptyRole ? ' (' + translated[translations?.user?.editUser?.emptyRoleSuffix] + ')' : ''} - ${data.groups[0].tenant}`;
  }

  if (combineGroups.length > 1) {
    groupText = `${groupText}, `;
    groupTooltipText = `+${combineGroups.length - 1} ${translated[translations.companyView.userList.tableCardMore]}`;
  }

  return {
    title: `${data?.user?.firstName ?? ''} ${data?.user?.middleName ?? ''} ${data?.user?.lastName ?? ''}`,
    groupTooltip: combineGroups.length > 1 ? combineGroups.join('\n') : '',
    /* status field uses the status of company user directory */
    status: `${translated[translations.companyView.userList.tableCardStatus]}: ${toTitleCase(data?.user?.status)}`,
    editText: translated[translations.companyView.userList.tableCardEditButton],
    editLink: `//company/${companyId}/users/${data?.user?.userId}/edit`,
    error: data?.isEmptyRole || false,
    groupText,
    groupTooltipText,
  };
}

export function buildUserListGroups(users, groups?: Array<IGroup>, applications = [], allApp = [], companyLevelSuffix?: string): Array<UserGroupPolicyCombine> {
  if (!users || !users.length) {
    return [];
  }

  const usersGroupPolicyCombine: UserGroupPolicyCombine[] = [];
  const companyLevelRoles = getAppRoleList(allApp) || [];
  users.forEach(user => {
    const userCombine: UserGroupPolicyCombine = {
      user,
      groups: [],
      isEmptyRole: null
    };
    groups?.forEach(group => {
      let clonePolicyName = group.policy.name;
      const policyName = !!group.policy?.grants?.find(grant => grant?.roles?.find(role => companyLevelRoles?.includes(role?.id))) ? clonePolicyName += ` (${companyLevelSuffix})` : clonePolicyName;
      const isEmptyRole = !group.policy.grants.some(grant => applications.some(app => app.id === grant.application.id));

      if (group.members.find(userGroup => userGroup.id === user?.userId)) {
        userCombine.groups.push({ id: group.id, policy: policyName, tenant: group.tenant?.name, isEmptyRole });
      }
    });
    userCombine.isEmptyRole = userCombine.groups.length ? userCombine.groups.some(group => group.isEmptyRole) : false;
    usersGroupPolicyCombine.push(userCombine);
  });
  return usersGroupPolicyCombine;
}

export function getAppRoleList(apps): string[] {
  let appRoleList: Array<string> = [];
  apps?.forEach(app => app?.roles?.map(({ allowedTenants, id }) => {
    if ((allowedTenants?.includes(ETenantType.SINGLE_COMPANY)) && !(allowedTenants?.includes(ETenantType.DIVISION_LIST))) {
      appRoleList.push(id);
    }
  }));

  return appRoleList;
}

export function buildCompanyDriver(userList, drivers) {
  userList?.map((item) => drivers.map(driver => {
    item['isUserDriver'] = item?.user?.id === driver?.userId;
  }));
}
