import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import * as DriversDataStoreActions from "./drivers-data-store.actions";
import { catchError, filter, mergeMap, switchMap } from "rxjs/operators";
import { CoreDriverApiService } from "@app/core/services/api/core-driver-api.service";

@Injectable()
export class DriversDataStoreEffects {
  constructor(
    private actions$: Actions,
    private driverApiService: CoreDriverApiService
  ) { }

  getDriverProfilesByUserId$ = createEffect(() => this.actions$.pipe(
    ofType(DriversDataStoreActions.getDriverProfilesByUserId),
    mergeMap((action) => {
      return this.driverApiService.getUserDrivers({ userId: action.payload.userId }).pipe(
        mergeMap((userDrivers) => {
          return this.driverApiService.getDriverProfiles({ driverId: userDrivers?.body[0].id }).pipe(
            mergeMap((driverProfiles) => {
              return [DriversDataStoreActions.getDriverProfilesByUserIdSuccessful({
                payload: { driverProfiles: driverProfiles?.body },
              })];
            }),
          );
        }),
        catchError((error) => {
          return [DriversDataStoreActions.getDriverProfilesByUserIdFailure({
            payload: { error }
          })];
        })
      );
    })
  ));

  getUserDriversByCompanyId$ = createEffect(() => this.actions$.pipe(
    ofType(DriversDataStoreActions.getUserDriversByCompanyId),
    mergeMap((action) => {
      return this.driverApiService.getUserDrivers({ companyId: action.payload.companyId }).pipe(
        mergeMap((userDrivers) => {
          return this.driverApiService.getDriverProfiles({ companyId: action.payload.companyId }).pipe(
            mergeMap((driverProfiles) => {
              const companyUserDrivers = userDrivers.body.filter((driver) => driverProfiles.body.find(profile => profile.driverId === driver.id));
              return [DriversDataStoreActions.getUserDriversByCompanyIdSuccessful({
                payload: { companyUserDrivers },
              })];
            }),
          );
        }),
        catchError((error) => {
          return [DriversDataStoreActions.getUserDriversByCompanyIdFailure({
            payload: { error }
          })];
        })
      );
    })
  ));
}
