import { IDriver, IDriverProfile } from "@app/core/models/driver.model";
import { createAction, props } from "@ngrx/store";

export enum ActionTypes {
  InitDrivers = '[DRIVERS] InitDrivers',

  GetDriverProfilesByUserId = '[DRIVERS] GetDriverProfilesByUserId',
  GetDriverProfilesByUserIdSuccessful = '[DRIVERS] GetDriverProfilesByUserIdSuccessful',
  GetDriverProfilesByUserIdFailure = '[DRIVERS] GetDriverProfilesByUserIdFailure',

  GetUserDriversByCompanyId = '[DRIVERS] GetUserDriversByCompanyId',
  GetUserDriversByCompanyIdSuccessful = '[DRIVERS] GetUserDriversByCompanyIdSuccessful',
  GetUserDriversByCompanyIdFailure = '[DRIVERS] GetUserDriversByCompanyIdFailure',

  ResetDriverProfiles = '[DRIVERS] ResetDriverProfiles',
}

export const initDrivers = createAction(ActionTypes.InitDrivers);

export const getDriverProfilesByUserId = createAction(ActionTypes.GetDriverProfilesByUserId, props<{ payload: { userId: string } }>());
export const getDriverProfilesByUserIdSuccessful = createAction(ActionTypes.GetDriverProfilesByUserIdSuccessful, props<{ payload: { driverProfiles: IDriverProfile[] } }>());
export const getDriverProfilesByUserIdFailure = createAction(ActionTypes.GetDriverProfilesByUserIdFailure, props<{ payload: { error: any } }>());

export const getUserDriversByCompanyId = createAction(ActionTypes.GetUserDriversByCompanyId, props<{ payload: { companyId: string } }>());
export const getUserDriversByCompanyIdSuccessful = createAction(ActionTypes.GetUserDriversByCompanyIdSuccessful, props<{ payload: { companyUserDrivers: IDriver[] } }>());
export const getUserDriversByCompanyIdFailure = createAction(ActionTypes.GetUserDriversByCompanyIdFailure, props<{ payload: { error: any } }>());

export const resetDriverProfiles = createAction(ActionTypes.ResetDriverProfiles);
